<template>
  <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-tabs">
                    <div class="card-header p-0 pt-1">
                        <ul class="nav nav-tabs" id="custom-tabs-two-tab" role="tablist">
                            <li class="pt-2 px-3"><h3 class="card-title">{{wholesaler.name}}</h3></li>
                            <li class="nav-item">
                                <a href="#custom-tabs-four-general" class="nav-link active" id="custom-tabs-four-general-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-general" aria-selected="true">Allgemein</a>
                            </li>
                            <li class="nav-item">
                                <a href="#custom-tabs-four-articles" class="nav-link" id="custom-tabs-four-articles-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-articles" aria-selected="false">Ware</a>
                            </li>
                            <li class="nav-item" v-if="$auth.check('wholesalers.documents')">
                                <a href="#custom-tabs-four-documents" class="nav-link" id="custom-tabs-four-documents-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-documents" aria-selected="false">Dokumente</a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="tab-content" id="custom-tabs-four-tabContent">
                                    <div class="tab-pane fade active show" id="custom-tabs-four-general" role="tabpanel" aria-labelledby="custom-tabs-four-general">
                                        <General :wholesaler="wholesaler" v-if="notEmptyObject(wholesaler)" />
                                    </div>

                                    <div class="tab-pane fade" id="custom-tabs-four-articles" role="tabpanel" aria-labelledby="custom-tabs-four-articles">
                                        <Ingredients :wholesaler="wholesaler" v-if="notEmptyObject(wholesaler)" />
                                    </div>

                                    <div class="tab-pane fade" v-if="$auth.check('wholesalers.documents')" id="custom-tabs-four-documents" role="tabpanel" aria-labelledby="custom-tabs-four-documents">
                                        <Documents :wholesaler="wholesaler" v-if="notEmptyObject('wholesaler')"/>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </b-container>
</template>

<script>
import General from './components/General';
import Ingredients from './components/Ingredients';
import Documents from './components/Documents';

export default {
    name: "WholesalersDetails",
    title: "Großhändler bearbeiten",

    components: {
        General,
        Ingredients,
        Documents
    },

    data() {
        return {
            wholesaler: {},
        }
    },

    methods: {
        loadWholesaler() {
            this.axios
                .get("/wholesalers/" + this.$route.params.id)
                .then((response) => {
                    this.wholesaler = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        }
    },

    created() {
        this.loadWholesaler();
    }
}
</script>

<style>

</style>