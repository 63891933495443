<template>
    <div class="row">
        <div class="col-md-12">

            <div class="card card-secondary card-outline">
                <div class="card-header">
                    <div class="card-title">
                        <h4><i class="fa fa-briefcase"></i> Waren</h4>
                        <p>Verfügbare Produkte</p>
                    </div>

                    <div class="card-tools">
                        <button type="button" class="mr-1 btn btn-default btn-sm" @click="$parent.loadWholesaler">
                            <i class="fas fa-fw fa-sync"></i>
                        </button>
                        <b-button v-if="!editMode" size="sm" variant="primary" @click="editModeOn">Bearbeiten</b-button>
                        <b-button v-if="editMode" size="sm" variant="default" @click="editModeOff" class="mr-1">Abbrechen</b-button>
                        <b-button v-if="editMode" size="sm" variant="success" @click="editWholesaler">Speichern</b-button>
                    </div>

                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="name" class="control-label">Name*</label>
                                <input v-model="form.name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('name')}" :disabled="!editMode">
                                <has-error :form="form" field="name"></has-error>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="number" class="control-label">Großhändlernummer*</label>
                                <input v-model="form.wholesalernumber" name="number" id="number" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('wholesalernumber')}" :disabled="true">
                                <has-error :form="form" field="wholesalernumber"></has-error>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="send_email" :disabled="!editMode">
                                    <label class="custom-control-label" for="customSwitch1">Bestellung per Mail</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="email" class="control-label">E-Mail*</label>
                                <input v-model="form.email" type="email" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('email')}" :disabled="form.send_email == 0 || !editMode">
                                <has-error :form="form" field="email"></has-error>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="send_phone" :disabled="!editMode">
                                    <label class="custom-control-label" for="customSwitch2">Bestellung per Telefon</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="email" class="control-label">Telefon*</label>
                                <input v-model="form.phone" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('phone')}" :disabled="form.send_phone == 0 || !editMode">
                                <has-error :form="form" field="phone"></has-error>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch3" v-model="send_web" :disabled="!editMode">
                                    <label class="custom-control-label" for="customSwitch3">Bestellung per Webshop</label>
                                </div>
                            </div>
                            <div class="form-group" >
                                <label for="url" class="control-label">URL*</label>
                                <input v-model="form.url" type="email" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('url')}" :disabled="form.send_web == 0 || !editMode">
                                <has-error :form="form" field="url"></has-error>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="street" class="control-label">Straße</label>
                                <input v-model="form.street" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('street')}" :disabled="!editMode">
                                <has-error :form="form" field="street"></has-error>
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="house_number" class="control-label">Nr.</label>
                                <input v-model="form.house_number" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('house_number')}" :disabled="!editMode">
                                <has-error :form="form" field="house_number"></has-error>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <label for="zipcode" class="control-label">PLZ</label>
                            <input v-model="form.zipcode" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('zipcode')}" :disabled="!editMode">
                            <has-error :form="form" field="zipcode"></has-error>
                        </div>

                        <div class="col-md-3">
                            <label for="city" class="control-label">Ort</label>
                            <input v-model="form.city" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('city')}" :disabled="!editMode">
                            <has-error :form="form" field="city"></has-error>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-4">
                            <label for="customernumber" class="control-label">Kunden-Nr.</label>
                            <input v-model="form.customernumber" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('customernumber')}" :disabled="!editMode">
                            <has-error :form="form" field="customernumber"></has-error>
                        </div>

                        <div class="col-md-4">
                            <label for="skonto" class="control-label">Skonto</label>
                            <input v-model="form.skonto" type="number" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('skonto')}" :disabled="!editMode">
                            <has-error :form="form" field="skonto"></has-error>
                        </div>

                        <div class="col-md-4">
                            <label for="min_order_amount" class="control-label">Mindestbestellwert</label>
                            <input v-model="form.min_order_amount" type="number" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('min_order_amount')}" :disabled="!editMode">
                            <has-error :form="form" field="min_order_amount"></has-error>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="name" class="control-label">Notizen</label>
                                <ckeditor :editor="editor" :config="editorConfig" v-model="form.notice" :disabled="!editMode"></ckeditor>
                                <has-error :form="form" field="notice"></has-error>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <label for="name" class="control-label">Bestellinfos</label>
                            <ckeditor :editor="editor" :config="editorConfig" v-model="form.orderinfo" :disabled="!editMode"></ckeditor>
                            <has-error :form="form" field="orderinfo"></has-error>
                        </div>
                    </div>
                    

                </div>
            </div>

        </div>
    </div>                                   
</template>

<script>
import ClassicEditor from './../../../assets/packages/ckeditor5/src/ckeditor';

export default {
    name: "General",
    props:[
        'wholesaler'
    ],

    data() {
        return {
            form: new window.Form({
                id: "",
                name: "",
                url: "",
                email: "",
                order_email: "",
                phone: "",
                notice: "",
                orderinfo: "",
                min_order_amount: "",
                skonto: "",
                send_email: "",
                send_phone: "",
                send_web: "",
                street: "",
                house_number: "",
                zipcode: "",
                city: "",
                number: "",
                wholesalernumber: "",
                customernumber: "",
            }),
            send_email: 0,
            send_phone: 0,
            send_web: 0,
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            modalTitle: "Neue Ware erfassen",
            editMode: false,
        }
    },

    watch:{
        wholesaler: function(){
            this.form.fill(this.wholesaler);
            this.send_email = this.wholesaler.send_email;
            this.send_phone = this.wholesaler.send_phone;
            this.send_web = this.wholesaler.send_web;
        },

        send_email: function(){
            //this.form.email = "";
            if(this.send_email == true)
            {
                this.form.send_email = 1;
            }
            else
            {
                this.form.send_email = 0;
            }
        },

        send_phone: function(){
            //this.form.phone = "";
            if(this.send_phone == true)
            {
                this.form.send_phone = 1;
            }
            else
            {
                this.form.send_phone = 0;
            }
        },

        send_web: function(){
            //this.form.web = "";
            if(this.send_web == true)
            {
                this.form.send_web = 1;
            }
            else
            {
                this.form.send_web = 0;
            }
        },
    },

     methods: {
        editModeOn() {
            this.editMode = true;
        },

        editModeOff() {
            this.form.reset();
            this.form.fill(this.wholesaler);
            this.form._method ="PUT";
            this.editMode = false;
        },

        editWholesaler() {
            this.form
                .put("/wholesalers/"+this.wholesaler.id)
                .then(() => {
                    this.$parent.loadWholesaler();
                    this.editModeOff();
                    this.$swal({
                        icon: "success",
                        title: "Änderungen gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                });
        },
    },

    created(){
        this.form.fill(this.wholesaler);
        this.send_email = this.wholesaler.send_email;
        this.send_phone = this.wholesaler.send_phone;
        this.send_web = this.wholesaler.send_web;
    }

}
</script>

<style>

</style>