<template>
  <div class="row">
    <div class="col-md-2">
      <div class="nav flex-column nav-tabs h-100" id="vert-tabs-tab" role="tablist" aria-orientation="vertical">
        <a class="nav-link active" id="vert-tabs-vertrag-tab" data-toggle="pill" href="#vert-tabs-vertrag" role="tab" aria-controls="vert-tabs-vertrag" aria-selected="true">Vertragsunterlagen</a>
        <a class="nav-link" id="vert-tabs-zertifikate-tab" data-toggle="pill" href="#vert-tabs-zertifikate" role="tab" aria-controls="vert-tabs-zertifikate" aria-selected="false">Zertifikate</a>
        <a class="nav-link" id="vert-tabs-bilder-tab" data-toggle="pill" href="#vert-tabs-bilder" role="tab" aria-controls="vert-tabs-bilder" aria-selected="false">Bilder</a>
        <a class="nav-link" id="vert-tabs-preisliste-tab" data-toggle="pill" href="#vert-tabs-preisliste" role="tab" aria-controls="vert-tabs-preisliste" aria-selected="false">Preislisten</a>
        <a class="nav-link" id="vert-tabs-sonstiges-tab" data-toggle="pill" href="#vert-tabs-sonstiges" role="tab" aria-controls="vert-tabs-sonstiges" aria-selected="false">Sonstiges</a>
      </div>
    </div>
    <div class="col-md-10">
      <div class="tab-content" id="vert-tabs-tabContent">
        <div class="tab-pane text-left fade active show" id="vert-tabs-vertrag" role="tabpanel" aria-labelledby="vert-tabs-vertrag-tab">
            <DocumentDetails type="vertrag" :wholesaler="wholesaler" v-if="notEmptyObject(wholesaler)" />
        </div>
        <div class="tab-pane fade" id="vert-tabs-zertifikate" role="tabpanel" aria-labelledby="vert-tabs-zertifikate-tab">
            <DocumentDetails type="zertifikate" :wholesaler="wholesaler" v-if="notEmptyObject(wholesaler)" />
        </div>
        <div class="tab-pane fade" id="vert-tabs-bilder" role="tabpanel" aria-labelledby="vert-tabs-bilder-tab">
            <DocumentDetails type="bilder" :wholesaler="wholesaler" v-if="notEmptyObject(wholesaler)" />
        </div>
        <div class="tab-pane fade" id="vert-tabs-preisliste" role="tabpanel" aria-labelledby="vert-tabs-preisliste-tab">
            <DocumentDetails type="preisliste" :wholesaler="wholesaler" v-if="notEmptyObject(wholesaler)" />
        </div>
        <div class="tab-pane fade" id="vert-tabs-sonstiges" role="tabpanel" aria-labelledby="vert-tabs-sonstiges-tab">
            <DocumentDetails type="sonstiges" :wholesaler="wholesaler" v-if="notEmptyObject(wholesaler)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentDetails from './DocumentDetails.vue';

export default {

    props: [
        'wholesaler',
    ],
    components:{
        DocumentDetails
    },

    data(){
        return {

        }
    }

}
</script>
